import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "@layout/index";
import BottomLayout from "@/layout/BottomLayout";
import TopCardWrap from "@/content/use-cases/TopCardWrap";
import GraphicText from "@/layout/GraphicText";

import Header from "@components/head";

import {
  Text,
  List,
  Button,
  ListItem,
  Box,
  Link as ChakraLink,
  VStack,
  Flex,
} from "@chakra-ui/react";
import { HOST_URL } from "@/utils/constant";

export const Head = () => {
  return (
    <Header
      ogUrl={`${HOST_URL}/use-cases/education`}
      title="AI Note-taker for Lectures | Airgram for Education"
      description="Airgram helps students and teachers be fully engaged in online classes through real-time transcription, timestamped notes and HD video recordings. Remote classes made easy."
    />
  );
};

const { useState } = React;

const IndexPage = () => {
  return (
    <Layout
      bottomLayout={
        <BottomLayout
          signGtmId="web_sign_up_cta_btn_use_case"
          description="We offer a special discount for students & non-profit organizations."
          buttonText="Try Out Now"
          buttonLink="https://app.airgram.io/signup"
        />
      }
    >
      <TopCardWrap>
        <Text w="80%" textAlign="center" fontSize="32px" as="h1">
          Maximize your learning potential by reducing repetitive work
        </Text>
        <Text textAlign="center" fontSize={18}>
          Keep your focus on new ideas and let us handle the catching-up
        </Text>

        <ChakraLink
          _hover={{
            textDecor: "none",
          }}
          lineHeight="52px"
          target="_blank"
          mt="24px"
          href="https://app.airgram.io/signup"
        >
          <Button
            data-gtm="web_sign_up_cta_btn_use_case"
            size="lg"
            colorScheme="brand"
          >
            Get Started Free
          </Button>
        </ChakraLink>
      </TopCardWrap>

      <VStack my="80px" gap={{ base: "40px", md: "80px", lg: "120px" }}>
        {/* 左边图片，右边文字的布局 */}
        <GraphicText
          placement="right"
          title="Automation makes sure you never fall behind schedule again"
          slot={
            <StaticImage
              loading="lazy"
              width={540}
              style={{ borderRadius: "12px" }}
              src="https://a.storyblok.com/f/167495/1124x802/bda40356fa/education1.png"
              alt="Use Case Team"
            />
          }
        >
          <Flex
            flexDir="column"
            gap="12px"
            flex={1}
            bgColor="white"
            borderRadius="12px"
            color="#220247"
          >
            <Text fontSize={18}>
              Check assignment due dates and calendar events. Prepare questions
              and talking points beforehand. Have the AI assistant join all your
              meetings on time automatically.
            </Text>
          </Flex>
        </GraphicText>

        {/* 左边文字，右边图片的布局 */}
        <GraphicText
          title="Free your hands and mind from multitasking during lectures"
          slot={
            <StaticImage
              loading="lazy"
              width={540}
              style={{ borderRadius: "12px" }}
              src="https://a.storyblok.com/f/167495/1194x820/a8f583dc6d/education2.png"
              alt="Google Meet Transcript"
            />
          }
        >
          <Flex
            flexDir="column"
            gap="12px"
            flex={1}
            bgColor="white"
            borderRadius="12px"
            color="#220247"
          >
            <Text fontSize={18}>
              Take notes together with your class. Drag and drop the professors’
              speech directly onto your notepad. Integrate your notes into your
              favorite digital notebook with one click.
            </Text>

            <List pl={0}>
              <ListItem>
                🔹 Supported integrations: Google Docs, Notion, Slack and
                Microsoft Word
              </ListItem>
              <ListItem>
                🔹 Export transcript with timestamps and speakers
              </ListItem>
              <ListItem>
                🔹 Create clips by highlighting paragraphs in transcripts
              </ListItem>
            </List>
          </Flex>
        </GraphicText>

        <GraphicText
          placement="right"
          title="Work harder, use your time smarter"
          slot={
            <StaticImage
              loading="lazy"
              width={540}
              style={{ borderRadius: "12px" }}
              src="https://a.storyblok.com/f/167495/1196x756/38a3e4167b/education3.png"
              alt="Google Meet Collaborative Notes"
            />
          }
        >
          <Flex
            flexDir="column"
            gap="12px"
            flex={1}
            bgColor="white"
            borderRadius="12px"
            color="#220247"
          >
            <Text fontSize={18}>
              Listen only to selected speakers throughout the recording.
              Identify the most important messages using AI. Skip silence and
              jump right to the meat of the conversation.
            </Text>
            <List pl={0}>
              <ListItem>
                🔹 Locate important messages with automated entity extraction
              </ListItem>
              <ListItem>
                🔹 Find precise content in all recording archives instantly with
                global search
              </ListItem>
            </List>
          </Flex>
        </GraphicText>

        <GraphicText
          title="Amplify individual strength to gain powerful group results"
          slot={
            <StaticImage
              loading="lazy"
              width={540}
              style={{ borderRadius: "12px" }}
              src="https://a.storyblok.com/f/167495/1298x771/7e79696abc/education4.png"
              alt="Google Meet Collaborative Notes"
            />
          }
        >
          <Flex
            flexDir="column"
            gap="12px"
            flex={1}
            bgColor="white"
            borderRadius="12px"
            color="#220247"
          >
            <Text fontSize={18}>
              Discuss with your group members how to assign different roles.
              Clarify group project responsibilities with action items. Collect
              and aggregate individual pieces before deadlines.
            </Text>
          </Flex>
        </GraphicText>
      </VStack>
    </Layout>
  );
};

export default IndexPage;
